<template>
    <div class="DeviceInfoList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="deviceForm" ref="deviceForm">
                    <el-form-item label="仪器名称">
                        <el-input v-model="deviceForm.deviceName" placeholder="请输入仪器名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器条码">
                        <el-input v-model="deviceForm.deviceCode" placeholder="请输入仪器条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器编号">
                        <el-input v-model="deviceForm.deviceNum" placeholder="请输入仪器编号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器分类">
                        <el-select placeholder="请选择仪器仪表分类" v-model="deviceForm.deviceOneClass">
                            <el-option v-for="(item, index) in deviceOneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button  class="btn" icon="el-icon-refresh" @click="$router.push({ name: 'whManage-chgLocal' })">位置更换</el-button>
                    <!-- <el-button  type="primary" @click="handleDown" icon="el-icon-upload2">导出</el-button> -->
                </div>
                
            </div>

            <!-- 列表 -->
            <el-table :data="deviceList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }" :cell-style="bgColor">
                <el-table-column prop="seq" label="序号" align="center" width="50px" >
                </el-table-column>
                <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center"> </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceCode" label="仪器条码" align="center">
                </el-table-column>
                <el-table-column prop="deviceType" label="仪器型号" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center">
                </el-table-column>
                <el-table-column prop="purchaseDate" label="购置日期" align="center">
                </el-table-column>
                <el-table-column prop="manufacturer" label="生产厂家" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center">
                </el-table-column>
                <el-table-column prop="location" label="保存地点" align="center">
                </el-table-column>
                <!-- <el-table-column prop="remark" label="备注" align="center">
                </el-table-column> -->

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="保养维护" @click="handleKeepLog(scope.row)">保养维护</el-button>
                        <el-button type="text" size="mini" title="状态评估" @click="handleAssess(scope.row)">状态评估</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="deviceForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>

<script>
import { searchDeviceData, importExcel } from '@/api/device'
import { getDeviceClassByLevel } from '@/api/deviceClass.js'
import { downloadExcel } from '@/api/download.js'

export default {
    data() {
        return {
            deviceOneClassList: [],
            deviceList: [],
            deviceForm: {
                type: '10',
                deviceCode: '',
                deviceName: '',
                deviceType: '',
                deviceNum: '',
                deviceOneClass: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,

        }
    },
    mounted() {
        const a = "fafff".slice(0, 1);
        console.log(a)
        this.loadDeviceOneClass()
        this.searchDeviceData()
    },
    methods: {

        handleSizeChange(val) {
            this.deviceForm.pageSize = val;
            this.deviceForm.pageNum = 1;
            this.searchDeviceData();
        },
        handleCurrentChange(val) {
            this.deviceForm.pageNum = val;
            this.searchDeviceData();
        },
        async loadDeviceOneClass() {
            const level = 1
            await getDeviceClassByLevel(level).then(res => {
                if (res.code === '000000') {
                    this.deviceOneClassList = res.t
                }

            })
        },

        //查询
        searchDeviceData() {
            searchDeviceData(this.deviceForm).then(res => {
                if (res.code === '000000') {
                    this.deviceList = res.t.list
                    this.total = res.t.total
                    let count = 1;
                    this.deviceList.forEach((item) => {
                        item.seq = count++
                        // item.userStatus ===1 ? (item.userStatus_text='启用') : (item.userStatus_text='停用')
                        // count= count +1;
                    })
                }

            })

        },

        //详情
        handleDetail({ id }) {
            this.$router.push({
                name: 'whManage-detail',
                params: {
                    id
                }
            })
        },
        handleKeepLog({ id }) {
            this.$router.push({
                name: 'whManage-addKeepLog',
                params: {
                    did: id
                }
            })
        },
        handleAssess({ id }) {
            this.$router.push({
                name: 'whManage-deviceAssess',
                params: {
                    did: id
                }
            })
        },

        //搜索
        handleSearch() {
            this.searchDeviceData(this.deviceForm);
        },
        //重置
        handleReset() {
            this.deviceForm = {
                type: '10',
                deviceName: '',
                deviceType: '',
                deviceNum: '',
                deviceOneClass: '',
                pageNum: 1,
                pageSize: 10
            }
            this.searchDeviceData(this.deviceForm);
        },

        //点击导入
        handleIn() {
            this.$refs.import.click();
        },
        //导入
        uploadIn(event) {
            let file = event.target.files; //获得文件
            for (let i = 0; i < file.length; i++) {
                //    上传类型判断
                let fileName = file[i].name;
                let idx = fileName.lastIndexOf(".");
                if (idx != -1) {
                    let ext = fileName.substr(idx + 1).toUpperCase();
                    ext = ext.toLowerCase();
                    if (ext !== "xls" && ext !== "xlsx") {
                        this.$message.warning('上传文件只能是excel文件!')
                        return;
                    } else {
                        this.importFile = file[i];
                    }
                }
            }
            let formData = new FormData(); //数据
            formData.append("file", this.importFile);
            importExcel(formData)
                .then((res) => {
                    if (res.code === '000000') {
                        this.$message.success("导入成功");
                    } else {
                        this.$message.success("导入失败");
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        bgColor({ row }) {
            let cellStyle = '';
            const { caution } = row
            console.log("caution")
            console.log(caution)
            switch (caution) {
                case '00':
                    cellStyle = 'background-color: #FFF;color: #000;'
                    break;
                case '10':
                    cellStyle = 'background-color: #FFE289;color: #000;'
                    break;
                case '20':
                    cellStyle = 'background-color: rgb(237, 83, 83);color: #FFF;'
                    break;
                default:
                    cellStyle = 'background-color: #FFF;color: #000;'
                    break;

            }

            return cellStyle;

        },

        //导出
        handleDown() {
            this.$fullScreenLoading.show("导出中...");
            
            downloadExcel(this.deviceForm,'/device/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // let filename = "自定义文件名称"+ '.xls'
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);

                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                    message: "导出成功~",
                    type: "success",
                });
                }, 500)
                

            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceInfoList {

    .el-form-item,
    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

    .delInfo {
        font-size: 16px;
        text-align: left;
        margin-bottom: 15px;
    }

}
</style>